import React from 'react'
import './Shop.css'
function Shop() {
  return (
      <div className='container'>
    <h1 className='hdr'>Shop</h1>
    <h2 className='hdr2'>Coming Soon</h2>
    </div>
  )
}

export default Shop